import React from "react";
import Layout from "../components/layout.js";
import ThankYouBow from "../content/assets/images/you-got-this.gif";

export default () => (
    <Layout>
        <div className="relative bg-white overflow-hidden">
            <div className="relative pt-6 pb-16 sm:pb-24 lg:pb-32">
                <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
                    <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                            <h1>
                                <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-5xl">
                                    <span className="block text-gray-900">
                                        Just one last thing to do
                                    </span>
                                </span>
                            </h1>
                            <p className="mt-4 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">Keep an eye on your inbox, if you don't see anything in 68 seconds, let me know and I'll get you sorted out. (hint: look for the subject that contains "Almost There")
                            </p>
                            <p className="mt-4 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">That being said, welcome to the community!</p>
                            <p className="mt-4 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">Speak Soon</p>
                            <p className="mt-4 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">Dermot</p>
                        </div>
                        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                            <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                                <img className="w-full" src={ThankYouBow} alt="" />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </Layout>
);
